.form-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 627px;
    width: 100%;
}
.form-main__input {
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #DBDBDB;
    border-radius: 3px;
    padding: 16px 20px;
    margin-bottom: 20px;
    &:focus {
        outline: none;
        border: 1px solid #00094A;
    }
    
    &::placeholder {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #00094A;
        opacity: 0.5;
    }
}
.form-main__inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    max-width: 627px;
    width: 100%;
}
.form-main__label {
    margin-right: 30px;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    &:last-child {
        margin-right: 0;
    }
}

.form-main__sub-input {
    max-width: 298px;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #DBDBDB;
    border-radius: 3px;
    padding: 16px 20px;
    &:focus {
        outline: none;
        border: 1px solid #00094A;
    }
    
    &::placeholder {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #00094A;
        opacity: 0.5;
    }
}
.form-main__sub-input-span {
    position: absolute;
    top: calc(100% + 5px);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FF3636;
    display: none;
}
.form-main__sub-input:invalid ~ .form-main__sub-input-span{
    display: block;
}  
.form-main__texarea {
    padding: 16px 20px;
    width: 100%;
    min-height: 150px;
    background-color: #FFFFFF;
    border: 1px solid #DBDBDB;
    border-radius: 3px;
    resize: vertical;
    margin-bottom: 20px;
    &:focus {
        outline: none;
        border: 1px solid #00094A;
    }
    
    &::placeholder {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #00094A;
        opacity: 0.5;
    }
}
.form-main__file {
    padding: 14px 25px;
    background-color: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.form-main__file-span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-left: 6px;
}
.form-main__btn {
    cursor: pointer;
    border: none;
    padding: 14px 53px;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px; 
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #30D2A3;
    border-radius: 3px;
    margin-top: 30px;
}
@media only screen and (max-width: 1042px) {
    .form-main {
        padding-bottom: 135px;
        max-width: 100%;
    }
    .form-main__inner {
        max-width: 100%;
    }
    .form-main__sub-input {
        max-width: 100%;
    }
}
@media only screen and (max-width: 690px) {
    .form-main__inner {
        flex-direction: column;
        // margin-bottom: 0;
    }
    .form-main__sub-input {
        max-width: 100%;
        margin-bottom: 20px;
    }
    .form-main__sub-input-span {
        top: calc(100% - 5px);
    }
    .form-main__btn {
        margin-top: 40px;
    }
}