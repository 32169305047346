/* Statistics Section */
.statistics__wrapper {
	padding-top: 60px;
	padding-bottom: 40px;
}

.statistics__list {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.statistics__item {
	display: flex;
	flex-direction: column;
	max-width: 145px;
}

.statistics__numbers {
	margin: 0;
	margin-bottom: 1;
	font-weight: bold;
	font-size: 48px;
	line-height: 59px;
	color: #00094a;
}

.statistics__heading {
	margin: 0;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #00094a;
}

/* Media Responsive */
@media screen and (max-width: 956px) {
	.statistics__wrapper {
		padding-top: 35px;
		padding-bottom: 30px;
	}

	.statistics__list {
		justify-content: center;
		margin: -15px;
	}

	.statistics__item {
		margin: 15px;
	}
}

@media screen and (max-width: 507px) {
	.statistics__list {
		justify-content: space-between;
	}
}

@media screen and (max-width: 595px) {
	.statistics__heading {
		font-size: 12px;
		line-height: 18px;
	}

	.statistics__numbers {
		font-size: 30px;
		line-height: 37px;
	}
}

@media screen and (max-width: 349px) {
	.statistics__heading {
		font-size: 10px;
		line-height: 18px;
	}

	.statistics__numbers {
		font-size: 25px;
		line-height: 37px;
	}
}

@media screen and (max-width: 308px) {
	.statistics__list {
		margin: 0px;
		margin-bottom: -15px;
	}

	.statistics__item {
		margin: 0px;
		margin-bottom: 15px;
	}
}
