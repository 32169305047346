.portfolio-desc__wrapper .container {
    padding-top: 65px;
    padding-bottom: 120px;
}

.portfolio-desc__list {
    list-style-type: none;
    margin: 0;
    margin-bottom: 40px;
    padding: 0;
}

.portfolio-desc__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
}

.portfolio-desc__item-heading {
    width: 100%;
    max-width: 550px;
    margin: 0;
    margin-right: 50px;
    padding-top: 8px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    border-top: 1px solid rgba($color: #000000, $alpha: 0.3);
}

.portfolio-desc__item-desc {
    width: 100%;
    max-width: 625px;
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
}

.portfolio-desc__challanges-list {
    width: 100%;
    max-width: 625px;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.portfolio-desc__challanges-item {
    position: relative;
    padding-left: 25px;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
}

.portfolio-desc__challanges-item svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.portfolio-desc__challanges-item + .portfolio-desc__challanges-item {
    margin-top: 23px;
}

.portfolio-desc__socicals-list {
    width: 100%;
    max-width: 625px;
    display: flex;
    justify-content: space-between;
    margin: 0;
    margin-left: auto;
    padding: 0;
    list-style-type: none;
}

.portfolio-desc__socials-link {
    display: inline-block;

    &:hover {
        opacity: 0.9;
    }
}

.portfolio-desc__socials-link-website {
    display: flex;
    align-items: center;
    padding: 20px 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    text-decoration: none;
    box-shadow: inset 0 0 0 2px #00094a;
    border-radius: 5px;
    transition: color 0.3s ease, background-color 0.3s ease;

    &:hover {
        color: white;
        background-color: #00094a;

        opacity: 1;
    }
}

.portfolio-desc__socials-link-website svg {
    margin-right: 10px;
}

@media only screen and (max-width: 600px) {
    .portfolio-desc__list {
        margin-bottom: 20px;
    }

    .portfolio-desc__item-heading {
        font-size: 16px;
        margin-right: 10px;
    }

    .portfolio-desc__item-desc {
        font-size: 14px;
    }

    .portfolio-desc__socicals-list {
        justify-content: center;
        flex-wrap: wrap;
    }

    .portfolio-desc__socials-link {
        margin: 10px;
    }
}

@media only screen and (max-width: 450px) {
    .portfolio-desc__item {
        flex-direction: column;
    }

    .portfolio-desc__item-heading {
        margin-right: 0;
        margin-bottom: 20px;
    }
}
