.form-social__title {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 15px;
}
.form-social__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}
.form-social__item {
    margin-right: 39.65px;
    
    &:last-child {
        margin-right: 0;
    }
}
.form-social__link {
    width: 42.35px;
    height: 30px;
    
    & > svg {
        width: 42.35px;
        height: 30px;
    }
}
@media only screen and (max-width: 1042px) {
    .form-social {
        position: absolute;
        top: calc(100% - 70px);
    }
}