.article-intro {
    padding-top: 20px;
    padding-bottom: 28px;
    display: flex;
    justify-content: center;
    background-image: url('../../assets/img/articleBG.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 973px 455px;
}
.article-intro__title {
    margin: 0;
    max-width: 880px;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    & > span {
        font-family: Bebas Neue;
        font-style: normal;
        font-weight: normal;
        font-size: 180px;
        line-height: 200px;
        color: #00094A;
    }
}
.article-intro__inner {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    
}
.article-intro__inner-span {
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 180px;
    line-height: 200px;
    color: #00094A;
}
.article-intro__inner-span-small {
    margin-left: 25px;
    position: absolute;
    top: 60%;
    right: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    opacity: 0.7;
    max-width: 219px;
}
.article-intro__inner-span-mid {
    align-self: flex-end;
}
.article-intro__inner-span-botm {
    
}

@media only screen and (max-width: 1042px) {
    .article-intro {
        background-image: url('../../assets/img/articleBG2.svg');
        background-position: calc(50% - 40px) calc(50% - 100px);
        background-size: contain;
    }
}
@media only screen and (max-width: 720px) {
    .article-intro {
        margin-top: 30px;
        margin-bottom: 69px;
        background-position: top center;
        background-size: 242px 266px;
    }
    .article-intro__title {
        max-width: 400px;
        & > span {
            font-size: 80px;
            line-height: 96px;
        }
    }
    .article-intro__inner-span {
        font-size: 80px;
        line-height: 96px; 
    }
    .article-intro__inner-span-small {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        max-width: 158px;
        top: 95%;
    }
}
@media only screen and (max-width: 400px) {
    .article-intro {
        background-position: top left;
    }
    .article-intro__inner-span-mid {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}