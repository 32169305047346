.portfolio-result .container {
    width: 100%;
    max-width: 950px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 55px;
}

.portfolio-result__img {
    margin-bottom: 50px;
}

.portfolio-result__heading {
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
}

.portfolio-result__paragraph {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
}

@media only screen and (max-width: 500px) {
    .portfolio-result__heading {
        font-size: 24px;
        line-height: 1.5;
    }

    .portfolio-result__paragraph {
        font-size: 18px;
        line-height: 1.5;
    }
}
