.art-post {
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.art-post__title {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #00094A;
    margin-bottom: 40px;
    align-self: flex-start;
}
.art-post__list {
    align-self: flex-start;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
}
.art-post__item {
    margin-right: 25px;
    margin-bottom: 30px;
    max-width: 407px;
    box-shadow: 0px 15px 50px rgba(0, 9, 74, 0.1); 
    display: flex;
    flex-direction: column;
    
    &:nth-child(3n) {
        margin-right: 0;
    }

    & > img {
        width: 100%;
        height: 300px;
        display: block; 
        object-fit: cover;
    }
}
.art-post__in-title {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    padding: 25px 20px 0px 25px;
    margin-bottom: 10px;
}
.art-post__text {
    margin: 0;
    margin-top: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    padding: 0px 25px 0px 25px;
    margin-bottom: 36px;
    
}
.art-post__inner {
    margin-top: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding-left: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.art-post__titme {
    margin: 0;
}
.art-post__link {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    width: 68px;
    height: 68px;
    background-image: url('../../assets/img/Arrow.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18px 16px;
    transition: all ease 0.2s;
    
    &:hover {
        border-left: 1px solid #06A39C;
        background-color: #06A39C;
        background-image: url('../../assets/img/ArrowWhite.svg');
        box-shadow: 0px 15px 50px rgba(0, 9, 74, 0.1);
    }
}
@media only screen and (max-width: 1000px) { 
    .art-post__list {
        flex-wrap: wrap;
    }
}
@media only screen and (max-width: 905px) { 
    .art-post__title {
        font-weight: bold;
        font-size: 15.5px;
        line-height: 19px;
        color: #00094A;
    }
    .art-post__list {
        flex-direction: column;
        align-self: center;
        margin-bottom: 30px;
    }
    .art-post__item {
        max-width: 700px;
        min-width: 320px;
        margin-right: 0;

        & > img {
            height: 236px;
        }
    }
    .art-post__in-title {
        padding: 20px 15px 0 15px;
    }
    .art-post__text {
        padding-left: 15px;
        padding-right: 15px;
    }
}