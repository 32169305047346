.site-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding-top: 16px;
	padding-bottom: 16px;
	white-space: nowrap;
	background-color: #fff;
	z-index: 9999999999;
}

.logo {
	display: block;
	transition: opacity 0.3s ease;
}

.logo:hover {
	opacity: 0.8;
}

.logo:active {
	opacity: 0.6;
}

.logo__img {
	display: block;
	min-width: 126px;
	min-height: 28px;
}

.site-header__wrapper {
	display: flex;
	align-items: center;
	position: relative;
}

.site-header__features {
	display: flex;
	align-items: center;
	margin-left: auto;
}

.site-header__tel-link {
	display: flex;
	align-items: center;
	transition: opacity 0.3s ease;
	cursor: pointer;
	text-decoration: none;
	color: #00094a;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	text-transform: uppercase;
}

.site-header__tel-link:hover {
	opacity: 0.8;
}

.site-header__tel-link:active {
	opacity: 0.6;
}

.site-header__night-mode::after {
	content: '';
	min-height: 22px;
	min-width: 1px;
	background-color: #00094a;
	background-repeat: no-repeat;
	margin-left: 32px;
}

.site-header__phonecall {
	min-width: 20px;
	min-height: 20px;
	margin-right: 10px;
}

/* Select Language: */
.site-header__select-lang {
	border: none;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	text-transform: uppercase;
	color: #00094a;
	background-color: transparent;
	outline: none;
	margin-left: 30px;
	cursor: pointer;
}

/* Request Button */
.site-header__request-button {
	padding: 15px 30px;
	margin-left: 30px;
	text-decoration: none;
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	text-transform: uppercase;
	color: #ffffff;
	background-color: #00094a;
	border-radius: 3px;
	transition: opacity 0.3s ease;
}

.site-header__request-button:hover {
	opacity: 0.8;
}

.site-header__request-button:active {
	opacity: 0.6;
}

/* Hamburger */
.site-header__hamburger {
	display: flex;
	position: relative;
	align-items: center;
	margin-left: 35px;
	width: 88px;
	height: 15px;
	border: none;
	background-color: transparent;
	padding: 0;
	cursor: pointer;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	z-index: 9999;
}

.hamburger__icon {
	margin-left: 10px;
}

.close__icon {
	display: none;
	margin-left: 10px;
}

.site-header__menu-list-open .hamburger__icon {
	display: none;
}

.site-header__menu-list-open .close__icon {
	display: block;
}

.site-header__hamburger-text-close {
	display: none;
}

.site-header__menu-list-open .site-header__hamburger-text-close {
	display: block;
}

.site-header__menu-list-open .site-header__hamburger-text-menu {
	display: none;
}

.site-header__menu-list {
	display: none;
	padding: 0;
	margin: 0;
	list-style: none;
	animation-duration: 1s;
	transition: transform 0.3s ease;
	animation-name: menuanimationsC;
}

.site-header__menu-list-open .site-header__menu-list {
	display: flex;
	flex-direction: column;
	position: fixed;

	top: 0;
	right: 50%;
	transform: translateX(calc(50% + 510px));
	padding: 150px 90px;
	background-color: #fff;
	// max-width: 512px;
	width: 40%;
	height: 100vh;
	z-index: 999;
	animation-name: menuanimation;
	animation-duration: 1s;
	transition: transform 0.3s ease;
}

@keyframes menuanimation {
	0% {
		transform: translateX(1500px);
	}
	100% {
		transform: translateX(calc(50% + 510px));
	}
}

@keyframes menuanimations {
	0% {
		transform: translateX(calc(50% + 510px));
		display: flex;
	}
	100% {
		transform: translateX(1500px);
		display: none;
	}
}

.site-header__menu-list-close .site-header__menu-list {
	animation-name: menuanimations;
	animation-duration: 1s;
	transition: transform 0.3s ease;
}

.site-header__menu-item + .site-header__menu-item {
	margin-top: 33px;
}

.site-header__menu-link {
	text-decoration: none;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: #00094a;
	transition: color 0.3s ease, opacity 0.3s ease;

	&:hover {
		color: #06a39c;
	}

	&:active {
		opacity: 0.6;
	}
}

.site-header__background-effect {
	display: none;
}

.site-header__menu-list-open .site-header__background-effect {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 725px) {
	.site-header__features {
		display: none;
	}

	.site-header__hamburger {
		margin-left: auto;
	}
}
